import {
    Button,
    Flex,
    Heading,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalProps,
    Text
} from "@chakra-ui/react";

type OfferModalProps = ModalProps;


const LoginModal: React.FC<OfferModalProps> = ({isOpen, onClose}) => {
    return (
        <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent  p={8} alignContent={'center'}>
                <ModalBody>
                    <Flex direction={'column'} justify={'center'} align={'center'}>
                        <Heading mb={4}>
                            Skrá inn
                        </Heading>
                        <Text>
                            Þú þarft að vera innskráður til að gera þetta
                        </Text>
                        <Link mt={4} href={'https://innskraning.island.is/?id=aflamidlun'}>
                            <Button>
                                Innskrá
                            </Button>
                        </Link>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default LoginModal;
