import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/react";
import {TradeListingRow} from "./TradeListingRow";
import React from "react";
import {TableListingRowProps} from "./SellTable";
import useWindowSize from "../../../hooks/useWindowSize";
import {MainTableMobile} from "./TableListingRow";


const SwapTableMobile = ({swapValue}: { swapValue: any }) => {
    return (
        <Table variant="simple" width={"100%"}>
            <Thead>
                <Tr>
                    <Th colSpan={1}>Tegund í boði</Th>
                    <Th/>
                    <Th colSpan={1}>Fyrir tegund</Th>
                </Tr>
            </Thead>
            <Thead>
                <Tr>
                    <Th>Tegund A</Th>
                    <Th/>
                    <Th>Tegund B</Th>

                </Tr>
            </Thead>
            <Tbody>
                {
                    swapValue?.map((s: any) => {
                        return <TradeListingRow
                            mobile={true}
                            fishA={{
                                fishType: s.saleA.fishType,
                                kgAmount: s.saleA.kgAmount,
                                pricePerKg: s.saleA.pricePerKg,
                                fishSystem: s.saleA.fishSystem
                            }}
                            fishB={{
                                fishType: s.saleB.fishType,
                                kgAmount: s.saleB.kgAmount,
                                pricePerKg: s.saleB.pricePerKg,
                                fishSystem: s.saleB.fishSystem
                            }}
                        />
                    })
                }
            </Tbody>
        </Table>)
}
export const SwapTable = ({swapValue}: { swapValue: any }): JSX.Element => {
    const {width} = useWindowSize();

    if (width < 1200) {
        return <SwapTableMobile swapValue={swapValue}/>
    }
    return (
        <Table variant="simple" width={"100%"}>
            <Thead>
                <Tr>
                    <Th colSpan={3}>Tegund í boði</Th>
                    <Th/>
                    <Th colSpan={3}>Fyrir tegund</Th>
                </Tr>
            </Thead>
            <Thead>
                <Tr>
                    <Th>Tegund A</Th>
                    <Th isNumeric>Kg</Th>
                    <Th isNumeric>Verð</Th>
                    <Th/>
                    <Th>Tegund B</Th>
                    <Th isNumeric>Kg</Th>
                    <Th isNumeric>Verð</Th>
                </Tr>
            </Thead>
            <Tbody>
                {
                    swapValue?.map((s: any) => {
                        return <TradeListingRow
                            fishA={{
                                fishType: s.saleA.fishType,
                                kgAmount: s.saleA.kgAmount,
                                pricePerKg: s.saleA.pricePerKg,
                                fishSystem: s.saleA.fishSystem
                            }}
                            fishB={{
                                fishType: s.saleB.fishType,
                                kgAmount: s.saleB.kgAmount,
                                pricePerKg: s.saleB.pricePerKg,
                                fishSystem: s.saleB.fishSystem
                            }}
                        />
                    })
                }

            </Tbody>
        </Table>)
};
export default SwapTable
