import {
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
} from "@chakra-ui/react";
import React from "react";

const FormField: React.FC<{ label: string; helperText: string }> = ({
    label,
    helperText,
    children,
}) => {
    return (
        <FormControl mb={8}>
            <FormLabel fontWeight={"bold"} color={"#144263"}>
                {label}
            </FormLabel>
            <FormHelperText mb={2}>{helperText}</FormHelperText>
            {children}
        </FormControl>
    );
};

export default FormField;
