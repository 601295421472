import React, { useMemo, useState } from "react";
import {
    Button,
    ChakraProvider,
    extendTheme,
    Flex,
    Heading,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { Home } from "./home/home";
import { QueryParamProvider } from "use-query-params";
import { Tilbod } from "./tilbod/tilbod";
import { Admin } from "./admin/admin";
import Innskraning from "./innskraning";
import AuthRoute from "../common/AuthRoute";
import { AuthContextProvider } from "../common/firebase/firebaseHooks";
import Fonts from "../common/utils/fonts";
import "../common/utils/fonts.css";
// TODO Replace with some actual Firebase check
export const isSuperAdmin = true;

const customTheme = extendTheme({
    colors: {
        blue: {
            900: "#0837DE",
            800: "#0837DE",
            700: "#0837DE",
            600: "#0837DE",
            500: "#0837DE",
            400: "#0837DE",
            300: "#0837DE",
            200: "#0837DE",
            100: "#0837DE",
        },
        yellow: {
            900: "#FEFFD3",
            800: "#FEFFD3",
            700: "#FEFFD3",
            600: "#FEFFD3",
            500: "#FEFFD3",
            400: "#FEFFD3",
            300: "#FEFFD3",
            200: "#FEFFD3",
            100: "#FEFFD3",
        },
    },
});

export const App = () => {
    // @ts-ignore
    const isIE = /*@cc_on!@*/ !!document.documentMode;

    return (
        <ChakraProvider theme={customTheme}>
            <Fonts />
            <Modal
                closeOnEsc={false}
                size={"2xl"}
                isOpen={isIE}
                onClose={() => console.log("nice")}
            >
                <ModalOverlay />
                <ModalContent p={8} alignContent={"center"}>
                    <ModalBody>
                        <Flex
                            direction={"column"}
                            justify={"center"}
                            align={"center"}
                        >
                            <Heading mb={4}>
                                Vefsíða ekki studd í Internet Explorer
                            </Heading>
                            <Text>
                                Vegna öryggisástæðna styjum við ekki við
                                Internet Explorer vafrann vinsamlegast opnið í
                                öðrum vafra
                            </Text>
                            <Flex>
                                <Link
                                    mr={4}
                                    mt={4}
                                    href={"https://www.google.com/chrome/"}
                                >
                                    <Button>Google chrome</Button>
                                </Link>
                                <Link
                                    mt={4}
                                    href={
                                        "https://support.apple.com/downloads/safari"
                                    }
                                >
                                    <Button>Safari</Button>
                                </Link>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <AuthContextProvider>
                <Router>
                    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                                path="/tilbod"
                                element={
                                    <AuthRoute>
                                        <Tilbod />
                                    </AuthRoute>
                                }
                            />

                            <Route
                                path="/innskraning"
                                element={<Innskraning />}
                            />

                            <Route
                                path="/admin"
                                element={
                                    <AuthRoute>
                                        <Admin />
                                    </AuthRoute>
                                }
                            />
                        </Routes>
                    </QueryParamProvider>
                </Router>
            </AuthContextProvider>
        </ChakraProvider>
    );
};

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter: React.FC = ({ children }) => {
    const reactRouterNavigate = useNavigate();
    const location = useLocation();

    const adaptedHistory = useMemo(() => {
        return {
            // can disable eslint for parts here, location.state can be anything
            replace(location: Location) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                // @ts-ignore
                reactRouterNavigate(location, {
                    replace: true,
                    // @ts-ignore
                    state: location.state,
                });
            },
            push(location: Location) {
                // @ts-ignore
                reactRouterNavigate(location, {
                    replace: false,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    // @ts-ignore
                    state: location.state,
                });
            },
        };
    }, [reactRouterNavigate]);
    // https://github.com/pbeshai/use-query-params/issues/196
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return children({ history: adaptedHistory, location });
};
