import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Skeleton,
    Table,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr, useColorMode,
    VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {PageWrapper} from "../../components/PageWrapper";
import {colorGenerator} from "../../common/utils/ColorGenerator";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import DBStructure from "../../dbStructure";
import Listing, {SwapListing} from "../../common/models/Listing";
import useOfferModal from "../../hooks/useOfferModal";
import {FishTypeItem} from "../home/components/SellTable";
import {useLocalAuthState} from "../../common/firebase/firebaseHooks";
import {TradeIcon} from "../../components/Icons/TradeIcon";

type MyOffer = {
    system: string;
    fish: string;
    buy?: number;
    sell?: number;
}

const OfferSwapRow = ({id, showClosed = false}: { id: string, showClosed: boolean }) => {
    // const {system, fish, buy, sell} = offer;
    //const color = colorGenerator(fish, 90, 50).backgroundColor;

    const [value, loading, error] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, id),
    );

    if (loading || !value) {
        return <Skeleton width={'100%'} height={'20px'}/>
    }
    const listing: SwapListing = value?.data() as SwapListing;

    if (!listing) {
        return <div> listing not found {id}</div>
    }


    const removeListing = async () => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, id), {status: 'CLOSED_SUCCESS'});
    }

    const review = async () => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, id), {status: 'PUBLIC'});

    }

    const fishA = listing.saleA;
    const fishB = listing.saleB;

    if(listing.status === 'CLOSED_SUCCESS' && !showClosed) {
        return null
    }
    
    return (

        <Tr>
            <Td>
                <FishTypeItem system={fishA.fishSystem} fish={fishA.fishType}/>
            </Td>
            <Td isNumeric>
                <Box>
                    <Box>
                        {Number(Number(fishA.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishA.pricePerKg ? Number(Number(fishA.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box>
            </Td>
            <Td><TradeIcon/></Td>
            <Td> <FishTypeItem system={fishB.fishSystem} fish={fishB.fishType}/></Td>
            <Td isNumeric>
                <Box>
                    <Box>
                        {Number(Number(fishB.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishB.pricePerKg ? Number(Number(fishB.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box>
            </Td>
            <Td isNumeric> <Tag size={'sm'} variant='solid' colorScheme='red'>
                {listing.status}
            </Tag></Td>
            <Td>
                {listing.status !== 'CLOSED_SUCCESS' ?
                    <Button onClick={() => {
                        removeListing();
                    }} colorScheme={"red"} width={"100%"}>Eyða</Button> : <Button onClick={() => {
                        review();
                    }} width={"100%"}>Endurvekja</Button>
                }
            </Td>
        </Tr>
    );
}

const OfferRow = ({id, showClosed}: { id: string, showClosed: boolean }) => {
    // const {system, fish, buy, sell} = offer;
    //const color = colorGenerator(fish, 90, 50).backgroundColor;

    const [value, loading, error] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, id),
    );


    if (loading || !value) {
        return <Skeleton width={'100%'} height={'20px'}/>
    }
    const listing: Listing = value?.data() as Listing;

    if (!listing) {
        return <div> listing not found {id}</div>
    }


    const removeListing = async () => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, id), {status: 'CLOSED_SUCCESS'});
    }

    const review = async () => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, id), {status: 'PUBLIC'});

    }


    const color = colorGenerator(listing.fishType, 90, 50).backgroundColor;

    if(listing.status === 'CLOSED_SUCCESS' && !showClosed) {
        return null
    }

    return (
        <Tr>
            <Td>

                <Tag colorScheme={listing.saleType === 'SELL' ? 'green' : 'blue'}>
                    {listing.saleType === 'SELL' ? 'SALA' : 'KAUP'}
                </Tag></Td>
            <Td>
                <FishTypeItem system={listing.fishSystem} fish={listing.fishType}/>
            </Td>
            <Td isNumeric>{listing.kgAmount ?? '-'} <strong>KG</strong> </Td>
            <Td isNumeric>{listing.pricePerKg ?? '-'} <strong>KR/KG</strong></Td>
            <Td isNumeric> <Tag size={'sm'} variant='solid' colorScheme='red'>
                {listing.status}
            </Tag></Td>
            <Td>
                {listing.status !== 'CLOSED_SUCCESS' ?
                    <Button onClick={() => {
                        removeListing();
                    }} colorScheme={"red"} width={"100%"}>Eyða</Button> : <Button onClick={() => {
                        review();
                    }} width={"100%"}>Endurvekja</Button>
                }
            </Td>
        </Tr>
    );
}

export const Tilbod = (): JSX.Element => {
    const {user, localUser} = useLocalAuthState();

    const [showClosed, setShowClosed] = useState(false);

    const [value, loading, error] = useCollection(
        collection(getFirestore(getFirebaseApp()), DBStructure.user.base, ...[user?.uid ?? 'UNKNOWN', DBStructure.user.listing]),
    );

    const [swapValue, swapLoading, swapError] = useCollection(
        collection(getFirestore(getFirebaseApp()), DBStructure.user.base, ...[user?.uid ?? 'UNKNOWN', DBStructure.user.swapListing]),
    );

    const {isOpen, close, open, setSwapIsOpen} = useOfferModal();
    const {colorMode} = useColorMode();


    return (
        <PageWrapper>
            <Flex width={"100%"} justifyContent={"space-between"} align="center">
                <Heading>Mín tilboð</Heading>
                <HStack spacing={4}>
                    <Button variant={'solid'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => open()}>
                        Gera tilboð
                    </Button>
                    <Button variant={'solid'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => setSwapIsOpen()}>
                        Gera skipti tilboð
                    </Button>
                </HStack>
            </Flex>
            <VStack width={"100%"} spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Box p={0} borderRadius={"lg"} width={"100%"}
                     overflow={'scroll'}>
                    <Heading size={"md"} p={4} mb={4}>Kaup & sala</Heading>
                    <Button variant={'outline'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => setShowClosed(!showClosed)}>
                        {showClosed ? 'Fela lokuð' : 'Sýna lokuð'}
                    </Button>
                    <Table variant='simple' width={"100%"}>
                        <Thead>
                            <Tr>
                                <Th>Sala/kaup</Th>
                                <Th>Tegund</Th>
                                <Th isNumeric>Magn</Th>
                                <Th isNumeric>Verð</Th>
                                <Th/>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loading ? <Skeleton width={'100%'} heigh={'300px'}/> : value?.docs.map(s => {
                                return <OfferRow showClosed={showClosed} key={s.data().listingId} id={s.data().listingId}/>
                            })
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack>

            <VStack width={"100%"} spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Heading mt={8}>Mín skiptiboð</Heading>
                <Box p={0} borderRadius={"lg"} width={"100%"}
                     overflow={'scroll'}>
                    <Heading size={"md"} p={4} mb={4}>Skiptiboð</Heading>
                    <Button variant={'outline'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => setShowClosed(!showClosed)}>
                        {showClosed ? 'Fela lokuð' : 'Sýna lokuð'}
                    </Button>
                    <Table variant='simple' width={"100%"}>
                        <Thead>
                            <Tr>
                                <Th>Tegund A</Th>
                                <Th isNumeric/>
                                <Th/>
                                <Th>Tegund B</Th>
                                <Th isNumeric/>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {swapLoading ? <Skeleton width={'100%'} heigh={'300px'}/> : swapValue?.docs.map(s => {
                                return <OfferSwapRow showClosed={showClosed} key={s.data().listingId} id={s.data().listingId}/>
                            })
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack>
        </PageWrapper>
    );
};
