import React, {useState} from 'react';
import {
    Box,
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
    Text,
    HStack,
    Button,
    Tag,
    FormControl, Flex, FormLabel, Switch,
    Popover,
    PopoverHeader,
    PopoverContent,
    PopoverCloseButton,
    PopoverBody,
    PopoverArrow,
    PopoverFooter,
    PopoverTrigger,
} from "@chakra-ui/react";
import {PageWrapper} from "../../components/PageWrapper";
import {FishTypeItem} from "../home/components/SellTable";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, orderBy, query, where} from "firebase/firestore";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import DBStructure from "../../dbStructure";
import {AdminTableListingRow} from "./components/AdminTableListingRow";
import React_2 from "framer-motion/dist/framer-motion";
import {AdminTableListingSwapRow} from "./components/AdminTableSwapListingRow";

export interface AdminProps {
}

export const Admin = ({}: AdminProps): JSX.Element => {

    const [statusFilter, setStatusFilter] = useState(false);
    const [value, loading, error] = useCollection(
        query(
            collection(getFirestore(getFirebaseApp()), DBStructure.adminListing.base),
            orderBy("created", "desc")
        ),
    );

    const [swapValue, swapLoading, swapError] = useCollection(
        query(
            collection(getFirestore(getFirebaseApp()), DBStructure.adminSwapListing.base),
            orderBy("created", "desc")
        ),
    );

    console.log(value?.docs,'swapValue');
    return (
        <PageWrapper>
            <VStack width={"100%"} spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Heading>Admin</Heading>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0'>
                        Only public
                    </FormLabel>
                    <Switch onChange={() => setStatusFilter(!statusFilter)} isChecked={statusFilter}/>
                </FormControl>
                <Box p={0} borderRadius={"lg"} width={"100%"}
                     overflow={'scroll'}>
                    <Heading size={"md"} my={2}>
                        Kaup/sala
                    </Heading>
                    <Table variant='simple' width={"100%"}>
                        <Thead>
                            <Tr>
                                <Th>Dags</Th>
                                <Th>Nafn</Th>
                                <Th>Tegund</Th>
                                <Th>Fiskur</Th>
                                <Th isNumeric>Verð</Th>
                                <Th isNumeric>Magn</Th>
                                <Th isNumeric>Status</Th>
                                <Th isNumeric/>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                value?.docs.sort((a,b) => {
                                    // @ts-ignore
                                    return new Date(a.data().created) - new Date(b.data().created)
                                }).map(d => {
                                    return <AdminTableListingRow filterPublic={statusFilter} adminListing={d.data() as any} id={d.id}/>
                                })
                            }
                        </Tbody>
                    </Table>
                    <Heading size={"md"} my={2} mt={8}>
                        Skipti
                    </Heading>
                    <Table variant='simple' width={"100%"}>
                        <Thead>
                            <Tr>
                                <Th>Dags</Th>
                                <Th>Nafn</Th>
                                <Th>Tegund A</Th>
                                <Th isNumeric>Verð A</Th>
                                <Th>Tegund B</Th>
                                <Th isNumeric>Verð B</Th>
                                <Th isNumeric>Status</Th>
                                <Th isNumeric/>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                swapValue?.docs.sort((a,b) => {
                                    // @ts-ignore
                                    return new Date(a.data().created) - new Date(b.data().created)
                                }).map(d => {
                                    return <AdminTableListingSwapRow filterPublic={statusFilter} adminListing={d.data() as any} id={d.id}/>
                                })
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack>
        </PageWrapper>
    );
}
