import React, {useState} from 'react';
import {PageWrapper} from "../../components/PageWrapper";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import {useCollection, useCollectionOnce} from "react-firebase-hooks/firestore";
import {collection, getFirestore, query, where} from "firebase/firestore";
import {MainTable, MainTableMobile} from "./components/TableListingRow";
import {
    Box, Button,
    Flex,
    Heading, HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Switch,
    Table,
    Tbody, Td,
    Th,
    Thead,
    Tr,
    Text,
    useDisclosure,
    VStack, useColorMode
} from "@chakra-ui/react";
import useOfferModal from "../../hooks/useOfferModal";
import {sendMailTo} from "../../common/utils/SendMailTo";
import DBStructure from "../../dbStructure";
import Listing, {FishSystem, fishSystems, FishType, fishTypes} from "../../common/models/Listing";
import {QueryDocumentSnapshot} from "@firebase/firestore";
import {useForm} from "react-hook-form";
import {fishes} from "../../components/Modals/fishes";
import DetailsModal, {InfoCard} from '../../components/Modals/DetailsModal';
import useWindowSize from "../../hooks/useWindowSize";
import {TradeListingRow} from "./components/TradeListingRow";
import SwapTable from "./components/SwapTable";


export interface FishOverview {
    fishType: FishType;
    fishSystem: FishSystem;
    buys: Listing[],
    sell: Listing[],
}


export const Home = (): JSX.Element => {
    const [listing, setListing] = useState<FishOverview>();
    const viewListing = useDisclosure();
    const {isOpen: isToogle, onOpen, onClose} = useDisclosure();
    const {colorMode} = useColorMode();
    const {width} = useWindowSize();
    const {isOpen, close, open, setSwapIsOpen} = useOfferModal();

    // {
    //     value?.docs.map(s => {
    //         return <div>
    //             temp documents
    //         </div>
    //     })
    // }

    const [value, loading, error] = useCollection(
        query(
            collection(getFirestore(getFirebaseApp()), DBStructure.publicListing.base),
            ...[where("status", "==", 'PUBLIC')]
        ),
    );

    const [swapValue, swapLoading, swapError] = useCollection(
        query(
            collection(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base),
            ...[where("status", "==", 'PUBLIC')]
        ),
    );

    const BeginingFishOverviews: FishOverview[] = ['A', 'K', 'A%', 'K%'].flatMap(ft => {
        return fishes.map(fs => {
            return {
                fishType: fs.fish as any,
                fishSystem: ft as FishSystem,
                buys: [] as Listing[],
                sell: [] as Listing[]
            };
        })
    })


    const getData = (d?: Array<QueryDocumentSnapshot>): Array<FishOverview> => {
        if (!d) {
            return BeginingFishOverviews
        }
        let values = BeginingFishOverviews.reduce((map, obj) => {
            // @ts-ignore
            map[obj.fishSystem + '-' + obj.fishType] = obj;
            return map;

        }, {});

        d.map((doc) => {
            const data: Listing = {...doc.data(), id: doc.id} as Listing;

            const index = data.fishSystem + '-' + data.fishType;
            // @ts-ignore

            if (data.saleType === 'BUY') {
                // @ts-ignore
                // @ts-ignore
                // @ts-ignore
                values[index] = {
                    // @ts-ignore
                    ...values[index],
                    // @ts-ignore
                    buys: [...values[index].buys, data]
                }
            } else {
                // @ts-ignore
                values[index] = {
                    // @ts-ignore
                    ...values[index],
                    // @ts-ignore
                    sell: [...values[index].sell, data]
                }
            }


        })
        return Object.values(values);

    }


    const some = getData(value?.docs)

    const setListingAndOpen = (listing: FishOverview) => {
        setListing(listing);
        viewListing.onOpen();
    };
    const finalData = !isToogle ? some.filter(s => {
        return !(s.sell.length === 0 && s.buys.length === 0)
    }) : some;

    return (
        <PageWrapper>
            <VStack width={"100%"} spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Flex width={'100%'} justify={'space-between'}><Heading>Yfirlit</Heading>

                    <Flex>
                    <Button mr={2} variant={'solid'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => open()}>
                        Gera tilboð
                    </Button>
                    <Button variant={'solid'} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} onClick={() => setSwapIsOpen()}>
                        Gera skipti tilboð
                    </Button>
                    </Flex>
                </Flex>
                <Box p={0} borderRadius={"lg"} width={"100%"}>

                    <Flex align={{lg: 'center', base: 'start'}} direction={{'lg': 'row', base: 'column'}}
                          justify={'space-between'}><Heading
                        size={"md"} p={4} mb={4} pl={0}>Kaup & sala
                        (kr/kg)</Heading>
                    </Flex>
                    {/*              <Flex align={'center'}>
                        <Text mr={2}>Sýna allt</Text>
                        <Switch size='lg' checked={isToogle} onChange={() => isToogle ? onClose() : onOpen()}/>
                    </Flex>*/}
                    <Flex direction={{xl: 'row', md: 'column', sm: 'column', base: 'column'}} width={'100%'}
                          overflow={'scroll'}>
                        <Box mr={{lg: '4', md: '0', sm: '0', base: 0}} flex={1}>
                            <Heading size={'sm'}>
                                Aflamark
                            </Heading>
                            <MainTable items={finalData.filter(s => s.fishSystem === 'A' || s.fishSystem === 'A%')}
                                       onOpen={(s) => setListingAndOpen(s)}/>
                        </Box>
                        <Box flex={1} mt={{lg: '0', md: '2', sm: '2', base: 2}}>
                            <Heading size={'sm'}>
                                Krókamark
                            </Heading>
                            <MainTable items={finalData.filter(s => s.fishSystem === 'K' || s.fishSystem === 'K%')}
                                       onOpen={(s) => setListingAndOpen(s)}/>
                        </Box>
                    </Flex>
                </Box>
                <Modal size={"2xl"} isOpen={viewListing.isOpen} onClose={viewListing.onClose}>
                    <DetailsModal listing={listing} viewListing={viewListing}/>
                </Modal>

                <Box p={0} borderRadius={"lg"} width={"100%"}
                     mt={8}>
                    <Heading my={4} size={"md"}>Skipti á afla</Heading>
                    <SwapTable swapValue={swapValue?.docs?.map(s => s.data())}/>
                </Box>
            </VStack>
        </PageWrapper>
    );
}
