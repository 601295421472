import {colorGenerator} from "../../../common/utils/ColorGenerator";
import {
    Button,
    HStack,
    MenuButton,
    Menu,
    Tag,
    Td,
    Text,
    Tr,
    MenuItem,
    MenuList,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Popover,
    PopoverHeader,
    PopoverContent,
    PopoverCloseButton,
    PopoverBody,
    PopoverArrow,
    PopoverFooter,
    PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import {BuySellItem, FishTypeItem, TableListingRowProps} from "../../home/components/SellTable";
import {useDocument, useDocumentOnce} from "react-firebase-hooks/firestore";
import {addDoc, collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import getFirebaseApp from "../../../common/firebase/getFirebaseApp";
import DBStructure from "../../../dbStructure";
import OrgUser from "../../../common/models/OrgUser";
import Listing from "../../../common/models/Listing";
import {format} from 'date-fns';

export const AdminTableListingRow = ({
                                         adminListing,
                                         filterPublic
                                     }: { adminListing: { userId: string, listingId: string }, id: string, filterPublic: boolean }): JSX.Element => {

    const [listingData, listingLoading, error] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, adminListing.listingId),
    );


    const [userData, userLoading, userError] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.tempUser.base, adminListing.userId),
    );


    if (listingLoading) {
        return <div>
            loading
        </div>
    }
    if (!listingData || !userData) {
        return <div>
            whatthefuck
        </div>
    }

    const removeListing = async () => {

        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, adminListing.listingId), {status: 'CLOSED_SUCCESS'});


    }

    const updateStatus = async (status: string, listing?: Listing) => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicListing.base, adminListing.listingId), {status: status});

        if (status === 'CLOSED_SOLD') {
            const docRef = await addDoc(
                collection(
                    getFirestore(getFirebaseApp()),
                    DBStructure.sales.base,
                ),
                {
                    fishType: listing?.fishType,
                    fishSystem: listing?.fishSystem,
                    price: listing?.pricePerKg,
                    kgAmount: listing?.kgAmount,
                    created: new Date().toString(),
                    lastUpdated: new Date().toString()
                },
            );
        }
    }

    const user = userData?.data() || {} as OrgUser;
    const listing = listingData.data() || {} as Listing;

    if (filterPublic && listing.status !== "PUBLIC") {
        return <></>;
    }
    return (
        <Tr>
            <Td>
                {format(new Date(listing.created), 'dd-MMM HH:MM')}
            </Td>
            <Td>
                <Text>{user.user.fullname ?? 'UNKNOWN'}</Text>
                <Text fontSize={10} opacity={0.8}>{user.user.kennitala ?? 'UNKNOWN'}</Text>
            </Td>
            <Td>
                {listing.saleType}
            </Td>
            <Td>
                <FishTypeItem system={listing.fishSystem} fish={listing.fishType}/>
            </Td>
            <Td isNumeric>
                {listing.pricePerKg} <strong>KR/KG</strong>
            </Td>
            <Td isNumeric>{listing.kgAmount} <strong>KG</strong></Td>
            <Td isNumeric>
                {listing.currentOfferId && <Offer currentOfferId={listing.currentOfferId}/>}
                <Tag size={'sm'} variant='solid' colorScheme='red'>
                    {listing.status}
                </Tag>
            </Td>
            <Td isNumeric>
                <HStack width={"100%"} spacing={4} justifyContent={"flex-end"}>


                    <Menu>
                        <MenuButton as={Button}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => updateStatus('PUBLIC')}>Set as public</MenuItem>
                            <MenuItem onClick={() => updateStatus('CLOSED_SOLD', listing as Listing)}>Set as
                                sold</MenuItem>
                            <MenuItem onClick={() => removeListing()}>Set as removed</MenuItem>
                            <MenuItem onClick={() => updateStatus('DRAFT')}>Set as draft</MenuItem>
                        </MenuList>
                    </Menu>

                </HStack>
            </Td>
        </Tr>
    );

    /*    return (
            <Tr>
                <Td>
                    <FishTypeItem system={system} fish={fish}/>
                </Td>
                <Td/>
                <Td>
                    <BuySellItem type={'K'} amount={buy}/>
                </Td>
                <Td>
                    <BuySellItem type={'S'} amount={sell}/>

                </Td>
                <Td/>

                <Td>
                    <Button width={"100%"} colorScheme={"blue"} size={"md"} onClick={onClick}>Sjá meira</Button>
                </Td>
            </Tr>
        );*/
}

const Offer = ({currentOfferId}: { currentOfferId: string }) => {

    const [offerData, offerDataLoading, error] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.offer.base, currentOfferId),
    );


    if (!offerData || offerDataLoading) {
        return null
    }

    console.log(offerData, 'fwfwf-')
    return (
        <Popover>
            <PopoverTrigger>
                <Tag cursor={'pointer'} mb={1}>
                    TILBOÐ!
                </Tag>
            </PopoverTrigger>
            <PopoverContent textAlign={'start'}>
                <PopoverArrow/>
                <PopoverCloseButton/>

                <PopoverBody>
                    <Heading fontSize={'18px'} pb={2}>
                        Núverandi tilboð
                    </Heading>
                    <Text>
                        Email: {offerData?.data()?.email}
                    </Text>

                </PopoverBody>
            </PopoverContent>
        </Popover>

    )

}
