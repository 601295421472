import React from 'react';

export const WalletIcon = ({color = '#53B9EA'}: { color?: string }): JSX.Element =>
    (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9 7C7.89543 7 7 7.89543 7 9V18C7 19.1046 7.89543 20 9 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7H9ZM14.5 12C13.6716 12 13 12.6716 13 13.5C13 14.3284 13.6716 15 14.5 15H16.5C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12H14.5Z"
                  fill={color}/>
        </svg>
    );
