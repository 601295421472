import React from 'react';
import {Box, Button, Td, Tr} from "@chakra-ui/react";
import {TradeIcon} from "../../../components/Icons/TradeIcon";
import {FishTypeItem} from "./SellTable";

type TradeFish = {
    fishType: string;
    kgAmount: number;
    pricePerKg: number;
    fishSystem: string;
}

export interface TradeListingRowProps {
    fishA: TradeFish;
    fishB: TradeFish;
    mobile?: boolean
}

export const TradeListingRow = ({fishA, fishB, mobile}: TradeListingRowProps): JSX.Element => {

    if (mobile) {
        return (<Tr>
            <Td> <FishTypeItem system={fishA.fishSystem} fish={fishA.fishType}/>
                <Box>
                    <Box>
                        {Number(Number(fishA.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishA.pricePerKg ? Number(Number(fishA.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box>
            </Td>
            <Td><TradeIcon/></Td>

            <Td> <FishTypeItem system={fishB.fishSystem} fish={fishB.fishType}/>
                <Box>
                    <Box>
                        {Number(Number(fishB.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishB.pricePerKg ? Number(Number(fishB.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box></Td>
        </Tr>);
    }
    return (
        <Tr>
            <Td> <FishTypeItem system={fishA.fishSystem} fish={fishA.fishType}/>
            </Td>
            <Td isNumeric>{Number(Number(fishA.kgAmount).toFixed(0)).toLocaleString()}<strong
                style={{paddingLeft: '8px'}}>kg</strong></Td>
            <Td isNumeric>{fishA.pricePerKg ? Number(Number(fishA.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                style={{paddingLeft: '8px'}}>kr/kg</strong></Td>
            <Td><TradeIcon/></Td>
            <Td> <FishTypeItem system={fishB.fishSystem} fish={fishB.fishType}/></Td>
            <Td isNumeric>{Number(Number(fishB.kgAmount).toFixed(0)).toLocaleString()}<strong
                style={{paddingLeft: '8px'}}>kg</strong></Td>
            <Td isNumeric>{fishB.pricePerKg ? Number(Number(fishB.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                style={{paddingLeft: '8px'}}>kr/kg</strong></Td>
            {/*         <Td>
                <Button width={"100%"} colorScheme={"blue"} size={"md"} onClick={undefined} variant={'outline'}>Sjá
                    meira</Button>
            </Td>*/}
        </Tr>
    );
}
