import React from "react";
import styled from "@emotion/styled";
import {
    Box,
    Button,
    ColorMode,
    Drawer,
    DrawerContent,
    Flex,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    Tag,
    TagLabel,
    Text,
    useColorMode,
    useDisclosure,
    VStack,
    Image,
    Heading,
} from "@chakra-ui/react";
import { DashboardIcon } from "../Icons/DashboardIcon";
import { getIconColor } from "../Icons/IconColor";
import { Link as RouterLink } from "react-router-dom";
import { WalletIcon } from "../Icons/WalletIcon";
import { sendContactMail } from "../../common/utils/SendMailTo";
import { ColorModeSwitcher } from "../../common/ColorModeSwitcher";
import { FaHamburger } from "react-icons/fa";
import { useLocalAuthState } from "../../common/firebase/firebaseHooks";
import { getAuth, signOut } from "firebase/auth";
import { InfoCard } from "../Modals/DetailsModal";
import { Logo } from "./Logo";
import { MenuIcon } from "./MenuIcon";

const NavigationContainer = styled.div<{ colorMode: ColorMode }>`
    position: sticky;
    top: 0;
    flex: 0 0 300px;
    width: 300px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    background: ${(props) =>
        props.colorMode === "light" ? "#FEFFD3" : "#0A2749"};

    flex-direction: column;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Desktop = styled(Box)`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Mobile = styled(Box)`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

export const NavigationLink = ({
    icon,
    text,
}: {
    icon: React.ReactNode;
    text: string;
}) => {
    return (
        <HStack spacing={4} cursor={"pointer"}>
            {icon}
            <Text fontWeight={"bold"}>{text}</Text>
        </HStack>
    );
};

export const Navigation = (): JSX.Element => {
    const { user, loading, error, localUser } = useLocalAuthState();

    const { colorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const isAdmin =
        user?.uid === "2006903389" ||
        user?.uid === "2604923149" ||
        user?.uid === "0305914299";
    const NavigationRender = () => {
        return (
            <VStack spacing={8} p={6} w={"100%"} alignItems={"flex-start"}>
                <Flex align={'center'}>
                    <Box boxSize={"58px"} mr={2}>
                        <Image src="./navigationLogo.png" alt="Dan Abramov" />
                    </Box>
                    <Logo />
                </Flex>

                <Box position={"relative"}>
                    <HStack width={"100%"} justify={"flex-end"}>
                        {!user ? (
                            <Link
                                href={
                                    "https://innskraning.island.is/?id=aflamidlun"
                                }
                            >
                                <Button>Innskráning</Button>
                            </Link>
                        ) : (
                            <Menu>
                                <MenuButton
                                    as={Tag}
                                    size="lg"
                                    variant="outline"
                                    cursor="pointer"
                                    colorScheme={
                                        colorMode === "light"
                                            ? "blue"
                                            : "yellow"
                                    }
                                    borderRadius="full"
                                >
                                    <TagLabel position={"relative"} top={0.5}>
                                        {localUser?.user?.fullname}
                                    </TagLabel>
                                </MenuButton>
                                <MenuList>
                                    <MenuGroup title="Aðgangur" pb={2}>
                                        <Text ml={4}>
                                            {localUser?.user?.kennitala}
                                        </Text>
                                        {/*
                                        <Text ml={4} fontSize={12}>{localUser?.user?.kennitala}</Text>
*/}
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuItem
                                        onClick={() => {
                                            const auth = getAuth();
                                            signOut(auth)
                                                .then(() => {
                                                    // Sign-out successful.
                                                    console.log("signedOut");
                                                })
                                                .catch((error) => {
                                                    // An error happened.

                                                    console.log("error");
                                                });
                                        }}
                                    >
                                        Útskráning
                                    </MenuItem>
                                    <MenuItem onClick={() => sendContactMail()}>
                                        Hafðu samband
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                        <ColorModeSwitcher
                            colorScheme={
                                colorMode === "light" ? "blue" : "yellow"
                            }
                            variant="outline"
                            size={"sm"}
                            color={
                                colorMode === "light"
                                    ? "blue.400"
                                    : "yellow.400"
                            }
                            borderRadius="full"
                            justifySelf="flex-end"
                        />
                    </HStack>
                </Box>

                <VStack spacing={6} w={"100%"} alignItems={"flex-start"}>
                    {isAdmin && (
                        <Link as={RouterLink} to={"/admin"}>
                            <NavigationLink
                                icon={
                                    <DashboardIcon
                                        color={getIconColor(true, colorMode)}
                                    />
                                }
                                text={"Admin"}
                            />
                        </Link>
                    )}
                    <Link as={RouterLink} to={"/"}>
                        <NavigationLink
                            icon={
                                <DashboardIcon
                                    color={getIconColor(true, colorMode)}
                                />
                            }
                            text={"Yfirlit"}
                        />
                    </Link>
                    <Link as={RouterLink} to={"/tilbod"}>
                        <NavigationLink
                            icon={
                                <WalletIcon
                                    color={getIconColor(true, colorMode)}
                                />
                            }
                            text={"Mín tilboð"}
                        />
                    </Link>
                    {/*<Link as={RouterLink} to={'/tilkynningar'}>*/}
                    {/*    <NavigationLink icon={<NotificationIcon color={getIconColor(true)} />} text={'Tilkynningar'} />*/}
                    {/*</Link>*/}
                    {/*<Link as={RouterLink} to={'/adgangur'}>*/}
                    {/*    <NavigationLink icon={<AccountIcon color={getIconColor(true)} />} text={'Adgangur'} />*/}
                    {/*</Link>*/}
                </VStack>
            </VStack>
        );
    };
    return (
        <>
            <Desktop>
                <NavigationContainer colorMode={colorMode}>
                    {NavigationRender()}
                    <Flex
                        p={6}
                        flexDirection={{ lg: "column", base: "column" }}
                        alignItems={{ lg: "start", base: "start" }}
                    >
                        <InfoCard />
                    </Flex>
                </NavigationContainer>
            </Desktop>
            <Mobile>
                <Flex
                    width={"100%"}
                    px={4}
                    height={"70px"}
                    bg={colorMode === "light" ? "#FEFFD3" : "#0A2749"}
                    align={"center"}
                    justifyContent={"space-between"}
                >
                    <Flex align={'center'}>
                        <Box boxSize={"58px"} mr={2}>
                            <Image src="./navigationLogo.png" alt="Dan Abramov" />
                        </Box>
                        <Logo />
                    </Flex>
                    <Box onClick={onOpen}>
                        <MenuIcon
                            color={
                                colorMode !== "light" ? "#FEFFD3" : "#0837DE"
                            }
                        />
                    </Box>
                </Flex>
                <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                    <DrawerContent>{NavigationRender()}</DrawerContent>
                </Drawer>
            </Mobile>
        </>
    );
};
