export const fishes = [
    {"id": 1, "fish": "Þorskur"},
    {"id": 2, "fish": "Ýsa"},
    {"id": 3, "fish": "Ufsi"},
    {"id": 5, "fish": "Karfi/gullkarfi"},
    {"id": 6, "fish": "Langa"},
    {"id": 7, "fish": "Blálanga"},
    {"id": 8, "fish": "Keila"},
    {"id": 9, "fish": "Steinbítur"},
    {"id": 11, "fish": "Úthafskarfi utan"},
    {"id": 13, "fish": "Hlýri"},
    {"id": 14, "fish": "Skötuselur"},
    {"id": 19, "fish": "Gulllax"},
    {"id": 22, "fish": "Grálúða"},
    {"id": 23, "fish": "Skarkoli"},
    {"id": 24, "fish": "Þykkvalúra"},
    {"id": 25, "fish": "Langlúra"},
    {"id": 27, "fish": "Sandkoli"},
    {"id": 28, "fish": "Skrápflúra"},
    {"id": 30, "fish": "Síld"},
    {"id": 31, "fish": "Loðna"},
    {"id": 34, "fish": "Kolmunni"},
    {"id": 36, "fish": "Makríll"},
    {"id": 38, "fish": "Norsk-íslensk síld"},
    {"id": 39, "fish": "Norsk-íslensk síld 2"},
    {"id": 40, "fish": "Humar"},
    {"id": 41, "fish": "Úthafsrækja"},
    {"id": 43, "fish": "Hörpudiskur"},
    {"id": 44, "fish": "Flæmingjarækja"},
    {"id": 45, "fish": "Þorskur - NL"},
    {"id": 46, "fish": "Þorskur - RU"},
    {"id": 47, "fish": "Flæmingjarækja 3L"},
    {"id": 48, "fish": "Ýsa - meðafli - RU"},
    {"id": 51, "fish": "Arnarfjarðarrækja"},
    {"id": 52, "fish": "Húnaflóarækja"},
    {"id": 53, "fish": "Rækja í Djúpi"},
    {"id": 54, "fish": "Skagafjarðarrækja"},
    {"id": 55, "fish": "Öxarfjarðarrækja"},
    {"id": 56, "fish": "Rækja í Skjálfanda"},
    {"id": 57, "fish": "Rækja í Breiðafirði"},
    {"id": 58, "fish": "Rækja við Snæfellsnes"},
    {"id": 60, "fish": "Litli karfi"},
    {"id": 61, "fish": "Arnarfjarðarskel"},
    {"id": 62, "fish": "Breiðafjarðarskel"},
    {"id": 63, "fish": "Dýrafjarðarskel"},
    {"id": 64, "fish": "Húnaflóaskel"},
    {"id": 65, "fish": "Skel í Djúpi"},
    {"id": 66, "fish": "Skel í Hvalfirði"},
    {"id": 72, "fish": "Eldeyjarrækja"},
    {"id": 73, "fish": "Innfjarðarrækja"},
    {"id": 85, "fish": "Úthafskarfi innan"},
    {"id": 94, "fish": "Skötuselur 2"},
    {"id": 95, "fish": "Djúpkarfi"}
];
