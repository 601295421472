import { initializeApp } from "firebase/app";
import { getApp, getApps } from "@firebase/app";

const getFirebaseApp = () => {
    if (!getApps() || (getApps() && getApps().length === 0)) {
        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: "AIzaSyAp9bwOIW7xzC3oh5-OXBQCdYrIngbI0Ig",
            authDomain: "kvotamidlun.firebaseapp.com",
            projectId: "kvotamidlun",
            storageBucket: "kvotamidlun.appspot.com",
            messagingSenderId: "1058535367931",
            appId: "1:1058535367931:web:a0868a8c9a9d6d40943c5e"
        };

        // Initialize Firebase
        return initializeApp(firebaseConfig);

    } else {
        return getApp();
    }
};

export default getFirebaseApp;
