import {getAuth, onAuthStateChanged} from '@firebase/auth'
import {useState, useEffect, useContext, createContext} from 'react'
import getFirebaseApp from "./getFirebaseApp";
import {useAuthState} from "react-firebase-hooks/auth";
import {doc, getDoc, getFirestore} from "firebase/firestore";

import DBStructure from "../../dbStructure";

export const AuthContext = createContext(null)
getFirebaseApp();

export const AuthContextProvider = (props: any) => {
    const [user, setUser] = useState()
    const [init, setInit] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        // @ts-ignore
        const unsubscribe = onAuthStateChanged(getAuth(), (u) => {

                setInit(true);
                // @ts-ignore
                setUser(u);
            },
            // @ts-ignore
            setError)
        return () => unsubscribe()
    }, [])
    return <AuthContext.Provider value={{user, error, init}} {...props} />
}
const auth = getAuth(getFirebaseApp());

export const useLocalAuthState = () => {

    const [user, loading, error] = useAuthState(auth);

    const [localUser, setLocalUser] = useState<{ user: any }>();

    useEffect(() => {
        console.log('fetching Local data')

        if (user && !localUser) {
            const docRef = doc(getFirestore(getFirebaseApp()), "temp-user", user.uid);
            getDoc(docRef).then(s => {

                // @ts-ignore
                setLocalUser(s.data());
            });


        }
    }, [user])
    return {
        localUser,
        user,
        loading,
        error
    }


}
