import {colorGenerator} from "../../../common/utils/ColorGenerator";
import {Button, HStack, MenuButton, Menu, Tag, Td, Text, Tr, MenuItem, MenuList, Box} from "@chakra-ui/react";
import React from "react";
import {BuySellItem, FishTypeItem, TableListingRowProps} from "../../home/components/SellTable";
import {useDocument, useDocumentOnce} from "react-firebase-hooks/firestore";
import {addDoc, collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import getFirebaseApp from "../../../common/firebase/getFirebaseApp";
import DBStructure from "../../../dbStructure";
import OrgUser from "../../../common/models/OrgUser";
import Listing, {SwapListing} from "../../../common/models/Listing";
import {format} from 'date-fns';
import {TradeIcon} from "../../../components/Icons/TradeIcon";

export const AdminTableListingSwapRow = ({adminListing, filterPublic}: { adminListing: { userId: string, listingId: string }, id: string, filterPublic:boolean }): JSX.Element => {

    const [listingData, listingLoading, error] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, adminListing.listingId),
    );


    const [userData, userLoading, userError] = useDocument(
        doc(getFirestore(getFirebaseApp()), DBStructure.tempUser.base, adminListing.userId),
    );


    if (listingLoading) {
        return <div>
            loading
        </div>
    }
    if (!listingData || !userData) {
        return <div>
            whatthefuck
        </div>
    }
    const removeListing = async () => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, adminListing.listingId), {status: 'CLOSED_SUCCESS'});
    }

    const updateStatus = async (status: string, listing?: SwapListing) => {
        var d = await updateDoc(doc(getFirestore(getFirebaseApp()), DBStructure.publicSwapListing.base, adminListing.listingId), {status: status});

        if (status === 'CLOSED_SOLD') {
            const docRef = await addDoc(
                collection(
                    getFirestore(getFirebaseApp()),
                    DBStructure.salesSwap.base,
                ),
                {
                    saleA: {
                        ...listing?.saleA
                    },
                    saleB: {
                        ...listing?.saleB
                    },
                    created: new Date().toString(),
                    lastUpdated: new Date().toString()
                },
            );
        }
    }

    const user = userData?.data() || {} as OrgUser;
    const listing = listingData.data() || {} as SwapListing;

    const fishA = listing.saleA;
    const fishB = listing.saleB;

    if (filterPublic && listing.status !== "PUBLIC") {
        return <></>;
    }
    return (
        <Tr>
            <Td>
                {format(new Date(listing.created), 'dd-MMM HH:MM')}
            </Td>
            <Td>
                <Text>{user.user.fullname ?? 'UNKNOWN'}</Text>
                <Text fontSize={10} opacity={0.8}>{user.user.kennitala ?? 'UNKNOWN'}</Text>
            </Td>
            <Td>
                <FishTypeItem system={fishA.fishSystem} fish={fishA.fishType}/>
            </Td>
            <Td isNumeric>
                <Box>
                    <Box>
                        {Number(Number(fishA.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishA.pricePerKg ? Number(Number(fishA.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box>
            </Td>
            <Td> <FishTypeItem system={fishB.fishSystem} fish={fishB.fishType}/></Td>
            <Td isNumeric>
                <Box>
                    <Box>
                        {Number(Number(fishB.kgAmount).toFixed(0)).toLocaleString()}<strong
                        style={{paddingLeft: '8px'}}>kg</strong>
                    </Box>
                    <Box>
                        {fishB.pricePerKg ? Number(Number(fishB.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                        style={{paddingLeft: '8px'}}>kr/kg</strong>
                    </Box>
                </Box>
            </Td>
            <Td isNumeric>
                <Tag size={'sm'} variant='solid' colorScheme='red'>
                    {listing.status}
                </Tag>
            </Td>
            <Td isNumeric>
                <HStack width={"100%"} spacing={4} justifyContent={"flex-end"}>
                    <Menu>
                        <MenuButton as={Button}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => updateStatus('PUBLIC')}>Set as public</MenuItem>
                            <MenuItem onClick={() => updateStatus('CLOSED_SOLD', listing as SwapListing)}>Set as sold</MenuItem>
                            <MenuItem onClick={() => removeListing()}>Set as removed</MenuItem>
                            <MenuItem onClick={() => updateStatus('DRAFT')}>Set as draft</MenuItem>
                        </MenuList>
                    </Menu>

                </HStack>
            </Td>
        </Tr>
    );

    /*    return (
            <Tr>
                <Td>
                    <FishTypeItem system={system} fish={fish}/>
                </Td>
                <Td/>
                <Td>
                    <BuySellItem type={'K'} amount={buy}/>
                </Td>
                <Td>
                    <BuySellItem type={'S'} amount={sell}/>

                </Td>
                <Td/>

                <Td>
                    <Button width={"100%"} colorScheme={"blue"} size={"md"} onClick={onClick}>Sjá meira</Button>
                </Td>
            </Tr>
        );*/
}
