const ContactMe = 'ingvi@aflamidlun.is';

type FishType = {
    system: string;
    fish: string;
}

export const sendMailTo = ({system, fish}: any) => {
    const subject = encodeURIComponent("Fyrirspurn frá vefsíðu");
    const body = encodeURIComponent(`Sæll Ingvi,\r\n\r\nÉg hef áhuga á að gera tilboð í ${fish} (Kerfi: ${system})\r\n\r\n>>> Fylltu út magn / verð <<<`);
    window.open(`mailto:${ContactMe}?subject=${subject}&body=${body}`, '_blank');
};

export const sendContactMail = () => {
    window.open(`mailto:${ContactMe}`, '_blank');
};
