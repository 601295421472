import { Global } from '@emotion/react'

const Fonts = () => (
    <Global
        styles={`
@font-face {
    font-family: 'Boing';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./Boing-Bold.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./Boing-Light.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./Boing-Medium.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./Boing-Semibold.ttf') format('ttf');

    .Boing {
        font-family: "Raleway";
    }

}

@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./Graphik-Light.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./Graphik-Medium.ttf') format('ttf'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./Graphik-Regular.ttf') format('ttf');

    .Graphik {
        font-family: "Graphik";
    }

}
      `}
    />
)

export default Fonts
