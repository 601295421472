const DBStructure = {
    offer:{
      base: 'offer'
    },
    publicListing: {base: 'publicListing'},
    publicSwapListing: {base: 'publicSwapListing'},
    adminListing: {base: 'adminListing'},
    adminSwapListing: {base: 'adminSwapListing'},
    sales: {
        base: 'sales'
    },
    salesSwap: {
        base: 'salesSwap'

    },
    user: {
        base: 'user',
        listing: 'listings',
        swapListing: 'swapListings',
    },
    tempUser: {
        base: 'temp-user'
    }
}

export default DBStructure
