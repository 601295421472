import React from 'react';

export const DashboardIcon = ({color = '#53B9EA'}: { color?: string }): JSX.Element =>
    (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7.10529" y="7.10529" width="4" height="4" rx="1.4" fill={color}/>
            <rect x="7.10529" y="13.1053" width="4" height="4" rx="1.4" fill={color}/>
            <rect x="13.1053" y="7.10529" width="4" height="4" rx="1.4" fill={color}/>
            <rect x="13.1053" y="13.1053" width="7" height="7" rx="1.4" fill={color}/>
        </svg>
    );
