import {Box, Text} from "@chakra-ui/react";

/*var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});*/


const FormatMoney = ({item}:{item?:number}) => {

    if(!item){
        return <>-</>
    }

    return <Box fontSize={'12px'} display={'flex'}>
        <Box mr={1}>{Number((item).toFixed(0)).toLocaleString()
        }</Box><Box>kr.</Box>
    </Box>

}


export default FormatMoney;
