import React from 'react';

export const TradeIcon = (): JSX.Element => {
    return (
        <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5L7.5 0.669872L7.5 9.33013L0 5Z" fill="#53B9EA"/>
            <path d="M24 5L16.5 0.669872V9.33013L24 5Z" fill="#53B9EA"/>
        </svg>
    );
}
