import React from 'react';

export const PhoneIcon = ({color = 'white'}: { color?: string }): JSX.Element => {
    return (
        <svg width="16" height="16" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.48 31.16C20.24 42.48 29.52 51.72 40.84 57.52L49.64 48.72C50.72 47.64 52.32 47.28 53.72 47.76C58.2 49.24 63.04 50.04 68 50.04C70.2 50.04 72 51.84 72 54.04V68C72 70.2 70.2 72 68 72C30.44 72 0 41.56 0 4C0 1.8 1.8 0 4 0H18C20.2 0 22 1.8 22 4C22 9 22.8 13.8 24.28 18.28C24.72 19.68 24.4 21.24 23.28 22.36L14.48 31.16Z"
                fill={color}/>
        </svg>
    );
}
