import {
    Button,
    Heading,
    Input,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalProps,
    Select,

} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {addDoc, collection, getFirestore} from "firebase/firestore";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import DBStructure from "../../dbStructure";
import FormField from "../form/FormField";
import {FishSystem, FishType, SaleType, Status} from "../../common/models/Listing";
import {useLocalAuthState} from "../../common/firebase/firebaseHooks";
import {fishes} from "./fishes";

type OfferModalProps = ModalProps;

type FormData = {
    fishType: FishType;
    fishSystem: FishSystem;
    expire: 7|14|30;
    saleType: SaleType;
    pricePerKg: number;
    kgAmount: number;
};

const OfferModal: React.FC<OfferModalProps> = ({isOpen, onClose}) => {
    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors, isValid, isSubmitting},
        reset
    } = useForm<FormData>();
    const {user, loading, error, localUser} = useLocalAuthState();

    const onSubmit = handleSubmit(async (data) => {
        // Add a new document with a generated id.
        const docRef = await addDoc(
            collection(
                getFirestore(getFirebaseApp()),
                DBStructure.publicListing.base,
            ),
            {
                ...data,
                userId: user?.uid,
                status: 'PUBLIC',
                created: new Date().toString(),
                lastUpdated: new Date().toString()
            },
        );
        alert('Þú bjóst til nýtt tilboð');

        reset();
        onClose();
    });

    return (
        <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent minHeight={"400px"} p={8}>
                <Heading mb={4}>
                    Tilboðsgerð
                </Heading>
                <form onSubmit={onSubmit}>
                    <FormField
                        label={"Kaup eða sala"}
                        helperText={
                            "Ertu að kaupa eða selja aflamark?"
                        }
                    >
                        <Select {...register("saleType", {required: true})} placeholder='Veldu hér'>
                            <option value='BUY'>Kaup</option>
                            <option value='SALE'>Sala</option>
                        </Select>

                    </FormField>
                    <FormField
                        label={"Aflamark eða krókaaflamark"}
                        helperText={
                            "Hvaða kerfi er aflinn í?"
                        }
                    >
                        <Select {...register("fishSystem", {required: true})} placeholder='Veldu hér'>
                            <option value='A'>Aflamark</option>
                            <option value='K'>Krókaaflamark</option>
                            <option value='A%'>Aflahlutdeild(%)</option>
                            <option value='K%'>Krókaaflahlutdeild(%)</option>
                        </Select>
                    </FormField>
                    <FormField
                        label={"Tegund"}
                        helperText={
                            ""
                        }
                    >
                        <Select {...register("fishType", {required: true})} placeholder='Veldu hér'>
                            {
                                fishes.map(f => {
                                    return (
                                        <option value={f.fish} key={f.fish}>
                                            {f.fish}
                                        </option>
                                    )

                                })
                            }
                        </Select>
                    </FormField>
                    <FormField
                        label={"Verð per kg."}
                        helperText={
                            ""
                        }
                    >
                        <Input
                            {...register("pricePerKg", {required: true})}
                            mr={8}
                            type="number"
                            placeholder={"00"}
                        />
                    </FormField>
                    <FormField
                        label={"Heildar kg. laus"}
                        helperText={
                            "Hvað eru mörg kg."
                        }
                    >
                        <Input
                            {...register("kgAmount", {required: true})}
                            mr={8}
                            type="number"
                            placeholder={"00"}
                        />
                    </FormField>
                    <FormField
                        label={"Tilboð rennur út eftir"}
                        helperText={
                            "Hvað margir dagar tilboðið er gilt"
                        }
                    >
                        <Select {...register("expire", {required: true})} placeholder='Veldu hér'>
                            {
                                [{label: '1 dag', value: 1}, {label: '7 daga', value: 7},{label: '30 daga', value: 30}].map(f => {
                                    return (
                                        <option value={f.value} key={f.value}>
                                            {f.label}
                                        </option>
                                    )

                                })
                            }
                        </Select>
                    </FormField>
                    <Button loading={isSubmitting} type="submit">Búa til</Button>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default OfferModal;
