import React from 'react';
import {Navigation} from "./Navigation/Navigation";
import {
    Flex,
    Box,
    Grid,
    VStack,
    Spinner,
    Center,
    HStack,
    Tag,
    Avatar,
    TagLabel,
    Menu,
    MenuButton,
    Text,
    MenuList, MenuDivider, MenuGroup, MenuItem
} from "@chakra-ui/react";
import {ColorModeSwitcher} from "../common/ColorModeSwitcher";
import OfferModal from "./Modals/OfferModal";
import useOfferModal from "../hooks/useOfferModal";
import {sendContactMail, sendMailTo} from "../common/utils/SendMailTo";
import LoginModal from './Modals/LoginModal';
import SwapOfferModal from "./Modals/SwapOfferModal";

export interface PageWrapperProps {
    children: React.ReactNode;
    isLoading?: boolean;
}

export const PageWrapper = ({children, isLoading = false}: PageWrapperProps): JSX.Element => {
    const {isOpen, close, isLoginOpen, isSwapOpen} = useOfferModal();

    return (
        <Flex maxWidth={'100vw'} height={'100vh'} overflow={'hidden'}
              direction={{'lg': 'row', md: 'row', base: 'column'}}>
            <Navigation/>
            <OfferModal isOpen={isOpen} onClose={close} children={null}/>
            <SwapOfferModal isOpen={isSwapOpen} onClose={close} children={null}/>
            <LoginModal isOpen={isLoginOpen} onClose={close} children={null}/>
            <Box p={{lg: 8, sm: 4, base: 2}} flex={1} maxWidth={'100vw'} overflow={'scroll'}>
                <VStack alignItems={"flex-start"}>

                    {isLoading ? (
                        <Grid width={"100%"} minH={"100vh"}>
                            <Center>
                                <Spinner/>
                            </Center>
                        </Grid>
                    ) : children}
                </VStack>
            </Box>
        </Flex>
    );
};
