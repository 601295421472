import {
    Button,
    Flex,
    Heading,
    Input,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalProps,
    Select,
    Box
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {addDoc, collection, getFirestore} from "firebase/firestore";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import DBStructure from "../../dbStructure";
import FormField from "../form/FormField";
import {FishSystem, FishType, SaleType, Status} from "../../common/models/Listing";
import {useLocalAuthState} from "../../common/firebase/firebaseHooks";
import {fishes} from "./fishes";

type SwapOfferModalProps = ModalProps;

type FormData = {
    fishType: FishType;
    fishSystem: FishSystem;
    pricePerKg: number;
    kgAmount: number;
};

const SwapOfferModal: React.FC<SwapOfferModalProps> = ({isOpen, onClose}) => {
    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        watch,
        formState: {errors, isValid, isSubmitting},
        reset
    } = useForm<{ saleA: FormData, saleB: FormData }>();
    const {user, loading, error, localUser} = useLocalAuthState();

    const onSubmit = handleSubmit(async (data) => {
        // Add a new document with a generated id.
        const docRef = await addDoc(
            collection(
                getFirestore(getFirebaseApp()),
                DBStructure.publicSwapListing.base,
            ),
            {
                ...data,
                salesType: 'swap',
                userId: user?.uid,
                status: 'PUBLIC',
                created: new Date().toString(),
                lastUpdated: new Date().toString()
            },
        );
        alert('Bjóst til nýtt skipti tilboð');

        reset();
        onClose();
    });

    const watchAllFields = watch(['saleA.pricePerKg', 'saleB.pricePerKg', 'saleA.kgAmount', 'saleB.kgAmount']);

    // @ts-ignore
    const valueA = getValues("saleA.kgAmount") * getValues("saleA.pricePerKg");

    // @ts-ignore
    const valueB = getValues("saleB.kgAmount") * getValues("saleB.pricePerKg");

    return (
        <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent minHeight={"400px"} p={8}>
                <Heading mb={4}>
                    Skipti tilboðsgerð
                </Heading>
                <form onSubmit={onSubmit}>
                    <Flex>
                        <Box mr={8}>
                            <Heading mb={2} fontSize={'22px'}>
                                Tegund A til að gefa frá
                            </Heading>
                            <FormField
                                label={"Aflamark eða krókaaflamark"}
                                helperText={
                                    "Hvaða kerfi er aflinn í?"
                                }
                            >
                                <Select {...register("saleA.fishSystem", {required: true})} placeholder='Veldu hér'>
                                    <option value='A'>Aflamark</option>
                                    <option value='K'>Krókaaflamark</option>
                                    <option value='A%'>Aflahlutdeild(%)</option>
                                    <option value='K%'>Krókaaflahlutdeild(%)</option>
                                </Select>
                            </FormField>
                            <FormField
                                label={"Tegund"}
                                helperText={
                                    ""
                                }
                            >
                                <Select {...register("saleA.fishType", {required: true})} placeholder='Veldu hér'>
                                    {
                                        fishes.map(f => {
                                            return (
                                                <option value={f.fish} key={f.fish}>
                                                    {f.fish}
                                                </option>
                                            )

                                        })
                                    }
                                </Select>
                            </FormField>
                            <Flex direction={{base: 'column', md: 'row'}}>
                                <Box mr={4}>

                                    <FormField
                                        label={"Verð per kg."}
                                        helperText={
                                            ""
                                        }
                                    >
                                        <Input
                                            {...register("saleA.pricePerKg", {required: true})}
                                            mr={8}
                                            type="number"
                                            placeholder={"0"}
                                        />
                                    </FormField>
                                </Box>
                                <FormField
                                    label={"Heildar kg. laus"}
                                    helperText={
                                        ""
                                    }
                                >
                                    <Input
                                        {...register("saleA.kgAmount", {required: true})}
                                        mr={8}
                                        type="number"
                                        placeholder={"0"}
                                    />
                                </FormField>
                            </Flex>
                        </Box>
                        <Box>
                            <Heading mb={2} fontSize={'22px'}>
                                Tegund B til að fá til baka
                            </Heading>
                            <FormField
                                label={"Aflamark eða krókaaflamark"}
                                helperText={
                                    "Hvaða kerfi er aflinn í?"
                                }
                            >
                                <Select {...register("saleB.fishSystem", {required: true})} placeholder='Veldu hér'>
                                    <option value='A'>Aflamark</option>
                                    <option value='K'>Krókaaflamark</option>
                                    <option value='A%'>Aflahlutdeild(%)</option>
                                    <option value='K%'>Krókaaflahlutdeild(%)</option>
                                </Select>
                            </FormField>
                            <FormField
                                label={"Tegund"}
                                helperText={
                                    ""
                                }
                            >
                                <Select {...register("saleB.fishType", {required: true})} placeholder='Veldu hér'>
                                    {
                                        fishes.map(f => {
                                            return (
                                                <option value={f.fish} key={f.fish}>
                                                    {f.fish}
                                                </option>
                                            )

                                        })
                                    }
                                </Select>
                            </FormField>
                            <Flex direction={{base: 'column', md: 'row'}}>
                                <Box mr={4}>
                                    <FormField
                                        label={"Verð per kg."}
                                        helperText={
                                            ""
                                        }
                                    >
                                        <Input
                                            {...register("saleB.pricePerKg", {required: true})}
                                            mr={8}
                                            type="number"
                                            placeholder={"0"}
                                        />
                                    </FormField>
                                </Box>
                                <FormField
                                    label={"Heildar kg. laus"}
                                    helperText={
                                        ""
                                    }
                                >
                                    <Input
                                        {...register("saleB.kgAmount", {required: true})}
                                        mr={8}
                                        type="number"
                                        placeholder={"0"}
                                    />
                                </FormField>
                            </Flex>
                        </Box>
                    </Flex>
                    <Box mb={2}>
                        <Box>
                            Heildarvirði A <strong>{(valueA ?? 0).toLocaleString('is', {
                                style: 'currency',
                                currency: 'ISK',
                            })}</strong>
                        </Box>
                        <Box>
                            Heildarvirði B <strong>{(valueB ?? 0).toLocaleString('is', {
                                style: 'currency',
                                currency: 'ISK',
                            })}</strong>
                        </Box>
                    </Box>
                    <Button loading={isSubmitting} type="submit">Búa til</Button>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default SwapOfferModal;
