import React, {useEffect} from 'react';
import {PageWrapper} from "../components/PageWrapper";
import {Spinner, Text} from "@chakra-ui/react";
import {useNavigate, useSearchParams} from "react-router-dom";
import getFirebaseApp from "../common/firebase/getFirebaseApp";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export interface InnskraningProps {
}

const Innskraning = ({}: InnskraningProps): JSX.Element => {
    const [searchParams] = useSearchParams();

    const reactRouterNavigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('token')) {
            console.log('trying')
            const auth = getAuth();
            signInWithCustomToken(auth, searchParams.get('token') ?? '')
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    // ...
                    reactRouterNavigate('/')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ...
                });


        }

    }, [])
    return (
        <PageWrapper>
            <Spinner size='xl' />
        </PageWrapper>

    );
}

export default Innskraning;
