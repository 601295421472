import React from 'react';
import {Box, Button, Flex, SkeletonCircle, Td, Tr, useColorMode} from "@chakra-ui/react";
import {colorGenerator} from "../../../common/utils/ColorGenerator";
import FormatMoney from "../../../components/FormatMoney";

export interface TableListingRowProps {
    system: string;
    fish: string;
    buy?: number;
    sell?: number;
    onClick: () => void;
}

export const FishTypeItem = ({system, fish, fontSize}: { system: string, fish: string, fontSize?: string }) => {

    const getSystemText = (s:string) => {

        if (s === 'A') {
            return 'Aflamark';
        } else if (s === 'K') {
            return 'Krókaaflamark';
        } else if (s === 'A%') {
            return 'Aflahlutdeild(%)';
        } else if (s === 'K%') {
            return 'Krókaaflahlutdeild(%)';

        }
    }

    return <Flex direction={'column'}>
        <Box fontWeight={'bold'} color={'black.200'} fontSize={fontSize === 'small' ? '13px' : 'initial'}>
            {fish}
        </Box>
        <Box fontSize={fontSize === 'small' ? '10px' : '12px'} color={'#777'}>
            {getSystemText(system)}
        </Box>

    </Flex>
}

export const BuySellItem = ({type, amount}: { type: string, amount?: number }) => {


    return <Flex fontWeight={'bold'}>
        <Box background={type === 'K' ? '#A2F38E' : '#E25D5D'} borderLeftRadius={'md'} color={'white'} py={1} px={2}
             borderTop={type === 'K' ? '1px solid #83D26F' : '1px solid #A43333'}
             borderLeft={type === 'K' ? '1px solid #83D26F' : '1px solid #A43333'}
             borderBottom={type === 'K' ? '1px solid #83D26F' : '1px solid #A43333'}>
            {type}
        </Box>

        <Flex pl={4}

              pr={1}
              borderRadius={'md'}
              borderLeftRadius={0}
              borderLeft={'none'}
              borderTop={'1px solid #E1E1E1'}
              borderRight={'1px solid #E1E1E1'}
              borderBottom={'1px solid #E1E1E1'}
              background={'#F7F7F7'} borderColor={''}
              align={'center'}
              color={'#65687A'}>
            <FormatMoney item={amount}/>
        </Flex>
    </Flex>
}


export const TableListingRow = ({system, fish, buy, sell, onClick}: TableListingRowProps): JSX.Element => {
    const color = colorGenerator(fish, 90, 50).backgroundColor;
    const { colorMode } = useColorMode();

    return (
        <Tr>
            <Td>
                <FishTypeItem system={system} fish={fish}/>
            </Td>
            <Td/>
            <Td>
                <BuySellItem type={'K'} amount={buy}/>
            </Td>
            <Td>
                <BuySellItem type={'S'} amount={sell}/>
            </Td>
            <Td/>

            <Td>
                <Button variant={'outline'} width={"100%"} colorScheme={colorMode === 'light' ? 'blue' : 'yellow'} size={"md"} onClick={onClick}>Sjá
                    meira</Button>
            </Td>
        </Tr>
    );
}

export const TableListingRowMobile = ({system, fish, buy, sell, onClick}: TableListingRowProps): JSX.Element => {
    const color = colorGenerator(fish, 90, 50).backgroundColor;

    return (
        <Tr onClick={onClick}>
            <Td>
                <FishTypeItem fontSize={'small'} system={system} fish={fish}/>
            </Td>
            <Td>
                <BuySellItem type={'K'} amount={buy}/>
            </Td>
            <Td>
                <BuySellItem type={'S'} amount={sell}/>
            </Td>
        </Tr>
    );
}
