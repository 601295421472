import React from 'react';
import {Box, Button, Flex, SkeletonCircle, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {colorGenerator} from "../../../common/utils/ColorGenerator";
import {FishOverview} from '../home';
import {TableListingRow, TableListingRowMobile} from './SellTable';
import useWindowSize from "../../../hooks/useWindowSize";

interface MainTableProps {
    onOpen: (s: any) => void;
    items: FishOverview[]

}

export const MainTable = ({items, onOpen}: MainTableProps): JSX.Element => {
    //const color = colorGenerator(fish, 90, 50).backgroundColor;
    const { width } = useWindowSize();

    if(width < 800){
        return <MainTableMobile onOpen={onOpen} items={items}/>
    }
    return (
        <Table variant='simple' width={"100%"} overflow={'scroll'}>
            <Thead>
                <Tr>
                    <Th>Tegund</Th>
                    <Th/>
                    <Th>Kaup</Th>
                    <Th>Sala</Th>
                    <Th/>
                    <Th/>
                </Tr>
            </Thead>
            <Tbody>

                {items.map((s: FishOverview) => {

                    const buy = Math.max(...s.buys.map(r => r.pricePerKg));
                    const sell = Math.min(...s.sell.map(r => r.pricePerKg));
                    return <TableListingRow system={s.fishSystem}
                                            fish={s.fishType}
                                            onClick={() => {
                                                onOpen(s)
                                            }}
                                            buy={isFinite(buy) ? buy : undefined}
                                            sell={isFinite(sell) ? sell : undefined}/>
                })}

            </Tbody>
        </Table>
    );
}

export const MainTableMobile = ({items, onOpen}: MainTableProps): JSX.Element => {
    //const color = colorGenerator(fish, 90, 50).backgroundColor;

    return (
        <Table variant='simple' width={"100%"} overflow={'scroll'} ml={-4}>
            <Thead>
                <Tr>
                    <Th>Tegund</Th>
                    <Th>Kaup</Th>
                    <Th>Sala</Th>
                </Tr>
            </Thead>
            <Tbody>

                {items.map((s: FishOverview) => {

                    const buy = Math.max(...s.buys.map(r => r.pricePerKg));
                    const sell = Math.min(...s.sell.map(r => r.pricePerKg));
                    return <TableListingRowMobile system={s.fishSystem}
                                            fish={s.fishType}
                                            onClick={() => {
                                                onOpen(s)
                                            }}
                                            buy={isFinite(buy) ? buy : undefined}
                                            sell={isFinite(sell) ? sell : undefined}/>
                })}

            </Tbody>
        </Table>
    );
}
