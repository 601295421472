import {
    Box,
    Button,
    Flex,
    HStack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
    Text,
    Popover,
    PopoverHeader,
    PopoverContent,
    PopoverCloseButton,
    PopoverBody,
    PopoverArrow,
    PopoverFooter,
    PopoverTrigger,
    Portal, Input, FormControl, FormLabel, Heading,
    useColorMode
} from "@chakra-ui/react";
import {sendMailTo} from "../../common/utils/SendMailTo";
import React, {useEffect, useState} from "react";
import {FishOverview} from "../../pages/home/home";
import {useCollection, useCollectionOnce} from "react-firebase-hooks/firestore";
import {addDoc, collection, getFirestore, orderBy, query, where} from "firebase/firestore";
import getFirebaseApp from "../../common/firebase/getFirebaseApp";
import DBStructure from "../../dbStructure";
import {format} from "date-fns";
import {PhoneIcon} from "../Icons/PhoneIcon";
import {MailIcon} from "../Icons/MailIcon";
import {useForm} from "react-hook-form";
import {useLocalAuthState} from "../../common/firebase/firebaseHooks";
import Listing from "../../common/models/Listing";
import useOfferModal from "../../hooks/useOfferModal";

const isToday = (someDate: Date) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}
const DetailsModal: React.FC<{ listing?: FishOverview, viewListing: any }> = ({listing, viewListing}) => {

    const [localOffer, setLocalOffer] = useState<string>();
    var ourDate = new Date();

    //Change it so that it is 7 days in the past.
    var pastDate = ourDate.getDate() - 7;
    ourDate.setDate(pastDate);

    const [value, loading, error] = useCollectionOnce(
        query(
            collection(getFirestore(getFirebaseApp()), DBStructure.publicListing.base),
            ...[where("fishType", "==", listing?.fishType),
                where("fishSystem", "==", listing?.fishSystem),
                where("status", "==", "CLOSED_SOLD"),
            ]
        ),
    );

    const allSales = value?.docs.map(c => c.data()).sort((a, b) => b.created - a.created);
    const thisWeekSales = allSales?.filter(as => isToday(new Date(as.created)));
    const lastSale = allSales && allSales?.length > 0 ? allSales[0] : null;
    return <><ModalOverlay/>
        <ModalContent maxW={"800px"}>
            <ModalHeader>{listing?.fishType + ' - ' + (listing?.fishSystem === 'A' ? 'Aflamark' : 'Krókamark')}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <VStack spacing={4} width={"100%"} justifyContent={"stretch"}>
                    <Flex mr={2} width={"100%"} direction={{'lg': 'row', base: 'column'}}>
                        <Table variant={'simple'}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={2}>Síðasta sala</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Verð</Td>
                                    <Td isNumeric>{lastSale ? lastSale?.pricePerKg + ' kr/kg' : 'N/A'}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Dagsetning</Td>
                                    <Td isNumeric>{lastSale ? format(new Date(lastSale?.created), 'dd-MMM HH:MM')
                                        : 'N/A'}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        <Table variant={'simple'}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={2}>Hreyfingar í dag</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Sölur</Td>
                                    <Td isNumeric>{thisWeekSales?.length ?? 0}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Magn</Td>
                                    <Td isNumeric>{thisWeekSales?.flatMap((s, b) => {
                                        return Number(s.kgAmount) + b
                                    }, 0) ?? 0}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Flex>
                    <Flex mr={2} width={"100%"} direction={{'lg': 'row', base: 'column'}}>
                        <Table variant={'simple'}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={3}>Kauptilboð</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    listing?.buys.map(b => {
                                        return <Tr>
                                            <Td>{Number(Number(b.kgAmount).toFixed(0)).toLocaleString()}<strong
                                                style={{paddingLeft: '8px'}}>kg</strong></Td>
                                            <Td isNumeric>{b.pricePerKg ? Number(Number(b.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                                                style={{paddingLeft: '8px'}}>kr/kg</strong></Td>
                                            <Td isNumeric>
                                                {b.currentOfferId != null  || localOffer === b.id ?
                                                    <Text fontStyle={'italic'} fontSize={'12px'} fontWeight={'bold'}>
                                                        Frátekið
                                                    </Text> :
                                                    <OfferPopOver offer={b} offerTaken={() => {
                                                        setLocalOffer(b.id)
                                                    }}/>}
                                            </Td>

                                        </Tr>
                                    })
                                }
                            </Tbody>
                        </Table>
                        <Table variant={'simple'}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={3}>Sölutilboð</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    listing?.sell.map(b => {
                                        return <Tr>
                                            <Td>{Number(Number(b.kgAmount).toFixed(0)).toLocaleString()}<strong
                                                style={{paddingLeft: '8px'}}>kg</strong></Td> <Td
                                            isNumeric>{b.pricePerKg ? Number(Number(b.pricePerKg).toFixed(0)).toLocaleString() : ''}<strong
                                            style={{paddingLeft: '8px'}}>kr/kg</strong></Td>
                                            <Td isNumeric>
                                                {b.currentOfferId != null || localOffer === b.id ?
                                                    <Text fontStyle={'italic'} fontSize={'12px'}
                                                          fontWeight={'bold'}>
                                                        Frátekið
                                                    </Text> :
                                                    <OfferPopOver offer={b}  offerTaken={() => {
                                                        setLocalOffer(b.id)
                                                    }}/>}
                                            </Td>
                                        </Tr>
                                    })
                                }
                            </Tbody>
                        </Table>
                    </Flex>
                </VStack>
            </ModalBody>
            <ModalFooter justifyContent={'space-evenly'}
                         flexDirection={{lg: 'row', base: 'column'}}
                         alignItems={{lg: 'center', base: 'start'}}>
                <InfoCard/>
            </ModalFooter>
        </ModalContent>
    </>
}

export const InfoCard = ({listing}: { listing?: FishOverview }) => {

    const {colorMode} = useColorMode();

    return <>
        <a href="tel:8673776">
            <Flex mr={4} align={'center'}
                  mb={{lg: '16px', base: '16px'}}
                  cursor={'pointer'}>
                <Flex align={'center'} justify={'center'} bg={colorMode === 'light' ? 'blue.400' : 'yellow.400'}
                      borderRadius={'50%'} p={2}>
                    <PhoneIcon color={colorMode !== 'light' ? '#0A2749' : undefined}/>
                </Flex>

                <Flex ml={3} direction={'column'}>
                    <Text fontWeight={'bold'} fontSize={'12px'}
                          color={colorMode === 'light' ? 'blue.400' : 'yellow.400'}>SÍMI</Text>
                    <Text>8673776</Text>
                </Flex>
            </Flex>

        </a>

        <Flex align={'center'}
              cursor={'pointer'}
              onClick={() => sendMailTo({system: listing?.fishSystem, fish: listing?.fishType})}>
            <Flex align={'center'} justify={'center'} bg={colorMode === 'light' ? 'blue.400' : 'yellow.400'}
                  borderRadius={'50%'} p={2}>
                <MailIcon color={colorMode !== 'light' ? '#0A2749' : undefined}/>
            </Flex>
            <Flex ml={3} direction={'column'}>
                <Text fontWeight={'bold'} fontSize={'12px'}
                      color={colorMode === 'light' ? 'blue.400' : 'yellow.400'}>EMAIL</Text>
                <Text>ingvi@aflamidlun.is</Text>
            </Flex>
        </Flex>
    </>
}

const OfferPopOver = ({offer, offerTaken}: { offer: Listing, offerTaken: () => void }) => {
    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors, isValid, isSubmitting},
        reset
    } = useForm<{ email: string }>();
    const {user, loading, error, localUser} = useLocalAuthState();
    const {openLogin} = useOfferModal();
    const onSubmit = handleSubmit(async (data) => {
        // Add a new document with a generated id.
        const docRef = await addDoc(
            collection(
                getFirestore(getFirebaseApp()),
                DBStructure.offer.base,
            ),
            {
                userId: user?.uid,
                email: data.email,
                offerId: offer.id,
                created: new Date().toString(),
                active: true,
            },
        );
        alert('Tilboð sent, haft verður samband við þig í gegnum email!');

        reset();
        offerTaken();
        close();
    });
    const [isOpen, setIsOpen] = React.useState(false)
    const open = () => {
        if(!user){
            openLogin()
        } else {
            setIsOpen(!isOpen);
        }
    }
    const close = () => setIsOpen(false)

    return <Popover
        isOpen={isOpen}
        onClose={close}>
        <PopoverTrigger>
            <Button onClick={open} size={'sm'}>Taka frá</Button>
        </PopoverTrigger>
        <PopoverContent textAlign={'start'}>
            <PopoverArrow/>
            <PopoverCloseButton/>

            <PopoverBody>
                <Heading fontSize={'18px'} pb={2}>
                    Taka frá tilboð
                </Heading>
                <form onSubmit={onSubmit}>
                    <FormControl pb={2}>
                        <FormLabel htmlFor='email'>Email </FormLabel>
                        <Input  {...register("email", {required: true})} id='email' type='email'/>
                    </FormControl>
                    <Button loading={isSubmitting} type="submit" colorScheme='blue' zIndex={6}>Staðfesta</Button>
                </form>
            </PopoverBody>
        </PopoverContent>
    </Popover>
}

export default DetailsModal;
