import { Route, Navigate } from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import getFirebaseApp from "./firebase/getFirebaseApp";
import {getAuth} from "@firebase/auth";
const auth = getAuth(getFirebaseApp());

const AuthRoute = ({ component: C, ...props } :any) => {
    const [user, loading, error] = useAuthState(auth);

    if(loading){
        return null;
    }
    if(user) {
        return props.children
    } else {
        return <Navigate to="/?loginModal=1" />
    }

}

export default AuthRoute;
