import {BooleanParam, useQueryParam} from "use-query-params";
import {useLocalAuthState} from "../common/firebase/firebaseHooks";
import {useNavigate} from "react-router-dom";


const useOfferModal = ()
    :
    {
        isOpen: boolean;
        isSwapOpen: boolean;
        isLoginOpen: boolean;
        open: () => void;
        setSwapIsOpen: () => void;
        close: () => void;
        openLogin: () => void;
    } => {
    const [isOpen, setIsOpen] = useQueryParam('offerModal', BooleanParam);
    const [isSwapOpen, setSwapIsOpen] = useQueryParam('swapModal', BooleanParam);
    const [isLoginOpen, setIsLoginOpen] = useQueryParam('loginModal', BooleanParam);
    const {user, loading, error, localUser} = useLocalAuthState();
    const reactRouterNavigate = useNavigate();

    return {
        isOpen: isOpen ?? false,
        isLoginOpen: isLoginOpen ?? false,
        isSwapOpen: isSwapOpen ?? false,
        openLogin: () => {
            setIsLoginOpen(true);
        },
        setSwapIsOpen: () => {
            if (!user) {
                setIsLoginOpen(true);
            } else {
                setSwapIsOpen(true);
            }
        },
        open: () => {
            if (!user) {
                setIsLoginOpen(true);
            } else {
                setIsOpen(true)
            }
        },
        close: () => {
            setIsOpen(false);
            setIsLoginOpen(false)
            setSwapIsOpen(false)
        }
    }
}


export default useOfferModal;
